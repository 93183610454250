
.ods{
  margin-top: 60px;
}
.center{
  text-align: center;
}
h1,h2,h3,h4,h5,h6{
  font-family: Fira Sans;
  font-weight: 700;
}
p,span{
  font-family: "Fira Sans";
  color: #777;
}
.firmazvol input, .firmazvol select{
  height: 60px;
}
.firmazvol input[type='submit']{
  width: 100%;
}
.blue{
  background-color: rgba(61,101,136,0.1);
  padding-top: 40px;
  padding-bottom: 80px;
  margin-top: 60px;
}
.centerize{
  text-align: center
}
.white{
  color: white!important;
}
.zakladatel{
  display: inline-block;
  margin-top: 20px;
  width: initial;
  height: initial!important;
}
.predmetyPodnikania{
  padding-left: 0;
  margin-left: 0;
}
.predmetyPodnikania li{
  padding-left: 0;
  list-style-type: none;
  margin-left: 0;
}
.predmetyPodnikania li{
  color: black;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Fira Sans";
}
.predmetyPodnikania{
  margin-top: 15px;
}
.whited{
  background-color: white;
  margin: 30px;
  border-radius: 30px;
  padding:20px;

}
.is-closed::after{
  content: "+";
  color: black;
  font-size: 20px;
  padding-left: 20px;
}
.is-open::after{
  content: "X";
  color: black;
  font-size: 20px;
  padding-left: 20px;
}
.minh{
  height: calc(100vh - 60px);
  margin-top: 0;
  padding-top: 35vh;
}
.black {
  background-color: #1a1a1a;
  color: #a1a1a1!important;
  padding-top: 20px;
  padding-bottom: 20px;
}
.noh{
  height: 15px!important;
}
.rr1 {
  text-align: right;
}
.navigation{
  text-align: center;
  padding-top: 15px;
}
.navigation a{
  padding-right: 15px;
}

.navigation .btn{
  background-color: #99caff;
  border: 2px solid #99caff;
  margin-right: 0!important;
  border-radius: 0!important;
  text-transform: uppercase;
  font-weight: 700;
  color: rgba(0,0,0,0.3)!important;
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
}


.navigation .btn-allowed:hover{
  cursor: pointer;
}
.navigation .btn:hover{
  cursor: pointer;
}
.navigation .btn-allowed.active{
  background-color: #007bff;
  border-color: #007bff!important;
  margin-right: 15px;
  opacity: 1;
  color: white!important;
}
.navigation .btn-allowed{
  background-color: #99caff;
  border: 2px solid #99caff;
  margin-right: 15px;
  color: black!important;



}
.posunsa{
  margin-right: 15px;
}
.rr1 a{
  color: #a1a1a1!important;
}
@media screen and (max-width: 768px) {
  .ll1, .rr1 {
    text-align: center;
  }
}
.odleft{
    margin-left: 15px;
}
.odleft:hover{
    cursor: pointer;
}
.onhovpointer:hover{
    cursor: pointer!important;
}
.btn:hover{
    cursor: pointer;
}
.whitecolor{
  color: white!important;
}
.Collapsible{
  display: block;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: black!important;
  border-radius: 20px;
  background-color: white;
  margin-bottom: 15px;


}
.error{
  color: darkred;
  font-weight: 700;
  text-transform: uppercase;
}
.success{
  color: #007bff;
  font-weight: 700;
  text-transform: uppercase;
}
.close{
  opacity: 1;
}
.cena p{
  font-weight: 700;
  color: black;
  text-transform: uppercase;
}
.cena{
  position: fixed;
  right: 0;
  top: 20vh;
  height: 400px;
  width: 200px;
  background-color: white;
  box-shadow: 1px 1px 10px 1px grey;
  padding: 30px;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
}
.spolocnici .mobile{
  display: none;
}
@media screen and (max-width: 768px){
  .spolocnici .table{
    display: none;
  }
  .spolocnici .mobile{
    display: block;
  }
}
.cenapric{
  font-weight: 700;

  color: black;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;

}
.allpricone{
  font-size: 1.3em;
  padding-top: 10px;
}
.opener{
  position: fixed;
  right: 0;
  bottom: 15vh;
  height: 50px;
  width: 50px;
  background-color: white;
  box-shadow: 1px 1px 10px 1px grey;
  padding-left: 13px;
  font-size: 35px;
  padding-top: 2px;
}
.npad{
  padding-left: 0;
}
.but{
  margin-right: 15px;
}
.noshowed{
  display: none;
}
.noshow{
  display: none;
}
.inline{
  display: inline-block;
  width: initial;
  height: initial!important;
  margin-top: 20px;
  padding-right: 15px;
}
.price{
  position: relative;
  top: -5px;
}
.Collapsible__contentInner a{
  margin-bottom: 15px;
}
.Collapsible__contentInner p{
  color: black;
}
.Collapsible span{
  color: black;
  text-transform: uppercase;
  font-weight: 700;
}
.whited p{
  margin-bottom: 0;
}
.namesub{
color: #EBEFF3!important;
}
.notification-warning{
  z-index: 999999999999999999999999!important;
}
.notification-container{
  z-index: 999999999999999999999999!important;
}
.item.btn-primary{
  color: white!important;
  border-radius: 30px;
}
.item.btn-primary a{
  color: white!important;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
}
.cookieConsent div {
  font-size: 14px;
}
.cookieConsent div a{
  color: white;
  text-decoration: underline;
}
.main{
  padding-top: 100px;
}
.navko{
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  z-index: 9999999999999999!important;
}
.whitesub{
  color: white;
}
.odsbot{
  margin-bottom: 60px;
}
.sidloSpolocnosti{
  margin-top: 40px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 480px){
  .centerize .but{
    margin-top: 15px;
    width: 200px;
  }
}
@media screen and (max-width: 416px) {
  .mobvyska{
    padding-top: 25px;
  }
}
